angular.module('core.appConfs').constant('AppConfs',
    {
        general: {
            restServices: {
                baseContext: '/rpol/private/rest',
				baseContextProfilePhoto: '/posteit-retail-service/rest/retail'
            }

        },
        scaConfs : {
            scaApp : "homePage"
        },
    
        HomePage: {

            codiceServizio: 'UTENTE_SERVICE',
            codiceServizioGestioneBuoni: 'RIMBORSO_BUONI',
			
			esitoPage : {
				exception0: {
                    headerMsg: 'Attenzione!',
                    classType: 'main-result-error',
                    bodyMsg: 'Impossibile eseguire l\'operazione.',
                    rowButtons: [
						{
							label: 'Riprova',
							style: 'btn-primary',
							disabled: false,
							clickAction: 'homeLoad_Handler',
							type: 'button'
						}
                    ]
                    
                }
			}
        }  
    }
);