angular.module('services.areaPremiumService').factory('areaPremiumService',
    ['$resource', '$filter', 'AppConfs',
        function($resource, $filter, AppConfs) {

            var requestMilliseconds = new Date().getTime();
            var dt = $filter('date')(new Date(), 'dd/MM/yyyy - HH:mm:ss');
            var nomeServizio = '/rpolConfigurazioneService';

            return $resource(
                AppConfs.general.restServices.baseContext + nomeServizio + '/:action', {}, {
                    areaPremium: {
                        method: 'POST',
                        headers: {
							requestID: 'PPAY.WEB.' + requestMilliseconds,
							requestTimestamp: dt
						},
                        params: {
                            
                            action: "elaborateMessage"
                        }
                    }
                });
        }
    ]);