/*NOTA: Always be explicit about the dependencies of a sub-module. 
Do not rely on dependencies inherited from a parent module.*/
angular.module('appRPOL_HomePageAPP',[
	'core.appConfs',
	'services.configurazioni',
	'services.utente',
	'services.librettoMinori',
	'services.profilePhoto',
	'filters.appAsteriskAccountNumber',
	'filters.mappaTipologiaLibretto',
	'services.fatca',
	'ngSanitize',
	'esitoPage',
	'rowButtons',
	'slickSlider',
	'services.webtrends',
	'services.satellite',
	'services.uikitProxy',
	'services.areaPremiumService',
	'directives.equalizeGroup',
	'ngCookies',
	'authenticationIdp',
	'services.scaUtils',
	'interceptors.sessionInterceptor'
    ]);


	angular.module('appRPOL_HomePageAPP').config(['$httpProvider','$locationProvider', function($httpProvider, $locationProvider){
		$locationProvider.html5Mode({
          enabled: true,
          rewriteLinks: false,
          requireBase: false
        });

		if (!$httpProvider.defaults.headers.get) {
			$httpProvider.defaults.headers.get = {};    
		}    

		// Answer edited to include suggestions from comments
		// because previous version of code introduced browser-related errors

		//disable IE ajax request caching
		$httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
		// extra
		$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
		$httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

		$httpProvider.interceptors.push('SessionInterceptor');
	}]);
	
angular.module('appRPOL_HomePageAPP').run([
    '$rootScope','$window',function($rootScope,$window){
        $rootScope.UrlBase=$window.location.host;
        $rootScope.UrlProtocol=$window.location.protocol+'//';
    }
]);