/*-----------------------------------
| Manager Controller
------------------------------------*/
angular.module('appRPOL_HomePageAPP').controller('ManagerController', 
			['$scope', '$rootScope', '$log', '$cookies', 'AppConfs', 'ConfigurazioniService', 'UtenteService', 'LibrettoMinoriService', 'FatcaService', 'ProfilePhotoService', '$timeout', '$filter', 'WebtrendsService', 'SatelliteService', 'ScaUtilsService', 'areaPremiumService',
	function ($scope, $rootScope, $log, $cookies, AppConfs, ConfigurazioniService, UtenteService, LibrettoMinoriService, FatcaService, ProfilePhotoService, $timeout, $filter, WebtrendsService, SatelliteService, ScaUtilsService, areaPremiumService) {

		var vm = $scope;

		//Home Vars
		vm.confs = {};
		vm.home_Confs = {};
		vm.home_Confs.esitoPage = {};
		vm.confs.mainFunction;
		vm.showEsito = false;
		vm.home_Confs.showLibrettoMinori = false;
		vm.showEsitoWarning = false;
		vm.showEsitoBuoni = false;
		vm.showEsitoHome = false;
		vm.disabilitaServizi = false;
		vm.gestionePratica = false;
		vm.redirectGestionePratica = redirectGestionePratica;
		vm.numeroPratica;
		vm.fatcaError = '';
		vm.rangeStati = [];
		vm.listaLibrettiInvocazione = {};
		vm.listaLibrettiInvocazione.confs;
		vm.listaLibrettiInvocazione.invocato = "";
		vm.listaLibrettiInvocazione.tipologia = "home";
		vm.closeModale = closeModale;
		vm.fatcaRedirect = fatcaRedirect;
		vm.home_Confs.pratiche = [];
		vm.eseguiDispositiva = eseguiDispositiva;
		/*	vm.isGold = false;*/

		vm.index;
		vm.home_Confs.SecurizzazioneLibretto = {}
		vm.checkSecurizzazioneLibretto = checkSecurizzazioneLibretto;
		vm.home_Confs.showModalWarning = false;

		//Home Funs
		vm.homeLoad_Handler = homeLoad_Handler;
		vm.getErrorResponseMessage = getErrorResponseMessage;
		var toOnBoardingLibApp = toOnBoardingLibApp;
		vm.manageClickOnBoxLib = manageClickOnBoxLib;
		vm.manageClickOnBoxPratica = manageClickOnBoxPratica;
		vm.manageClickOnLibrettoMinori = manageClickOnLibrettoMinori;


		//--------------------------
		//RowButtons Vars
		vm.rowButtons_Confs;
		//RowButtons Funs
		vm.rowButtons_ButtonClick_Handler = rowButtons_ButtonClick_Handler;

 
		startHome();


		function startHome() {
		//	show_CustomUIKit_PageLoader();
			console.log("onInit appCotroller");
			var url = window.location.search.substring(1);
			var json = ScaUtilsService.checkAuthFlow(url);
			//sono nel flusso di autenticazione idp
			console.log("json",json);
			if (!json) {
				activate();
			}
		}
			//Inizializzazione
	//	activate();

		function redirectGestionePratica(page) {
			// secure connection
			var config = {
				secure: true
			};
			// cript cookies
			var encodeFunnel = window.btoa('home');
			// pass cripted cookies in secure
			$cookies.putObject('funnel', encodeFunnel, config);
			// redirect
			window.location = page == 'riprendi' ? '/rpol/private/pages/acquisto-buono-e-libretto.html' : '/rpol/private/pages/gestione-pratica.html';
		}

		function fatcaRedirect(page){
			switch (page) {
				case 'up':
					window.location = "https://www.poste.it/cerca/index.html";
					break;
				case 'libretto':
					window.location = "https://buonielibretti.poste.it/buoni-e-libretti-sicurezza-online.html";
					break;
				case 'conto':
					window.location = "https://www.poste.it/operare-in-sicurezza.html";
					break;
				case 'fatca':
					window.location = "/rpol/private/pages/fatca.html";
					break;
			}
		}

		function activate() {
			vm.confs.mainFunction = "HomePage";
			$log.log('ManagerController confs : ', vm.confs);

			$timeout(function () {
				show_CustomUIKit_PageLoader();
			}, 0);


			// VERIFICA TITOLARE EFFETTIVO //
			UtenteService.titolareEffettivo({
				data: {
					tipoOperazione: 'verifica'
				}
			},
				function (response) {
					vm.home_Confs.titolareEffettivo = response.data.flagTitolare;

					configureHome();
					$log.log('UtenteService.titolareEffettivo:', response);
				},
				function (httpResponse) {
					vm.home_Confs.titolareEffettivo = true;
					configureHome();
					$log.log('UtenteService.titolareEffettivo:', httpResponse);
				}
			);

		}

		function configureHome() {
			ConfigurazioniService.configurazioni({
				data: {
					codiceServizio: AppConfs[vm.confs.mainFunction].codiceServizio
				}
			},
				function (response) {
					/*	if (response.data && response.data.isGold && response.data.isGold === "true") {
							vm.isGold = true;
						}*/
					$log.log('ConfigurazioniService.configurazioni:', response);
							if (response.data.listaConfigurazioni) {

						for (var i = 0; i < response.data.listaConfigurazioni.length; i++) {
							if (response.data.listaConfigurazioni[i].chiave.toUpperCase() == "SKIP_FATCA_BUONI") {
								vm.skipFatca = response.data.listaConfigurazioni[i].valore;
							}
							if (response.data.listaConfigurazioni[i].chiave.toUpperCase() == "CODICIPRATICAOPEN") {
								vm.rangeStati = response.data.listaConfigurazioni[i].valore.split(',');;
							}
						}
					}


					// recupero eventuale token area premium, se presente --> redirect
					var sessionStorageResponse = (sessionStorage.getItem("apVarTmp")) ? JSON.parse(atob(sessionStorage.getItem("apVarTmp"))) : null;
					if (sessionStorageResponse && sessionStorageResponse.data && sessionStorageResponse.data.mappaVariabiliTemp 
						&& sessionStorageResponse.data.mappaVariabiliTemp.apToken && sessionStorageResponse.data.mappaVariabiliTemp.apToken!="")				
						{

							window.location.href = window.location.href+"?"+sessionStorageResponse.data.mappaVariabiliTemp.apToken;				        	
							sessionStorage.removeItem("apVarTmp");
				        }
				        else
				        {
				        	richiediRapporti();
				        }

					
					/* 	richiediPratiche(); */
					/* 	richiediRapportiMinori(); */
					//vm.showEsito = false;
				},
				function (httpResponseError) {
					$log.log('ConfigurazioniService.configurazioni:', httpResponseError);
					// SE HTTP == 453 PRIMO WARNING MOSTRO IL MESSAGGIO DI WARNING CHE MI RESTITUISCI ELSE PAGINA DI ERRORE	CON ANNULLA E RIPROVA
					//console.log("httpResponseError: "+httpResponseError.status);
					//console.log("httpResponseError.metadata.message: "+httpResponseError.data.metadata.message);
					//flag isGold in caso di errore
					/*	if (httpResponseError.data.metadata && httpResponseError.data.metadata.isGold && httpResponseError.data.metadata.isGold === "true") {
							vm.isGold = true;
						}*/
					vm.messaggioErroreWarning = vm.getErrorResponseMessage(httpResponseError);
					$log.log('ConfigurazioniService.configurazioni:', vm.messaggioErroreWarning);
					if (httpResponseError.status === 453) {
						vm.showEsitoHome = true;
						vm.showEsitoWarning = true;
					} else {
						webtrendsLogError(null, vm.messaggioErroreWarning);
						configureEsitoPage(vm.messaggioErroreWarning);
						vm.showEsito = true;
					}
					$timeout(function () {
						hide_CustomUIKit_PageLoader();
					}, 0);
				}
			);
		}

		function richiediRapportiMinori(callBackEmpty) {

			LibrettoMinoriService.verifica(null,
				function (response) {
					if (response && response.data) {
						if (parseInt(response.data.numeroFigli) > 0) {
							vm.home_Confs.showLibrettoMinori = true;
						}
						else {
							vm.home_Confs.showLibrettoMinori = false;
						}
					}
					vm.showEsito = false;
					hide_CustomUIKit_PageLoader();
				},
				function (httpResponseError) {
					/* 	vm.messaggioErroreWarning = vm.getErrorResponseMessage(httpResponseError);
						webtrendsLogError(null, vm.messaggioErroreWarning);
						configureEsitoPage(vm.messaggioErroreWarning);
						vm.showEsito = true; */

					vm.home_Confs.showLibrettoMinori = false;
					//variabile di guardia, serve a mostrare pagina errore se e solo se tutte le chiamate di popolamento della pagina sono andate in errore
					if (vm.showErrorRapporti && vm.showErrorePratiche) {
						vm.messaggioErroreWarning = vm.getErrorResponseMessage(httpResponseError);
						webtrendsLogError(null, vm.messaggioErroreWarning);
						configureEsitoPage(vm.messaggioErroreWarning);
						vm.showEsito = true;
						hide_CustomUIKit_PageLoader();
					}
					else {
					vm.showEsito = false;
						hide_CustomUIKit_PageLoader();
					}

				}

			);
		}



		function checkPremium() {
			// AREA PREMIUM

				//1- verifica parametro in qeuery string
				var searchStr = decodeURIComponent(window.location.search.substr(1));
                searchStr = (searchStr && searchStr.length>0) ?  searchStr.replace(/ /g,'+') : "";

				// verifico presenza altri parametri in QS
				//var multiParam = searchStr.indexOf("&");

				//estrazione eventuali coppie parametro/valore
				var parmsArr = searchStr.split("&");

				

				// filtro array di coppie parametro/valore
				var fltParArr = parmsArr.filter(function(pp) {
				  
				  //creo array parametro,valore splittando "="
				  var pA = pp.split("=");
				  
				  
				  // se la posizione 2 dell'array ha una lunghezza maggiore di zero
				  // allora è un valore di un parametro nominale(nome=valore) quindi lo escludo
				  if (pA[1] && pA[1].length>0)
				    return false;
				  
				  // gestione di eventuali parametri mandati vuoti
				  // se arriva parametro= senza valore, potrebbe essere confuso con un b64
				  // verifico in primis se il nome può essere decodificato (mi esclude ad esempio i nomi tipo "wt.ac" che contiene il punto e non è valido b64)
				  // se passa il check di decodifica, controllo che sia lungo almeno 90 (il json, encodato e codificato, dovrebbe essere di almeno 128 caratteri: caso limite json con solo prop senza valori)
				  try {				    
				    return window.atob(pA[0]) &&  window.atob(pA[0]).length>90;
				  }
				  catch(e) {    				    
				    return false;
				  }				  				  
				})

				var multiParam = (fltParArr) ? fltParArr.length : 0;

				var reqJson = {data:{}};

				if (searchStr && searchStr!="") {
					//se nell'array ho un solo elemento, dovrebbe essere areaPremium avendo scartato tutto il resto.
					if (multiParam==1)
					{
						reqJson.data.message = fltParArr[0];

			         //pulisco query string
			         setTimeout(function() {
			             window.history.replaceState(null, "", window.location.protocol + '//' + window.location.host + window.location.pathname);
			         }, 1000);

			         areaPremium(reqJson);
			     } else {
			         console.log("AREA PREMIUM: errore, presenti troppi parametri in Query String");
			     }
			 }
		}



		function areaPremium(jm) {

                    console.log("AREA PREMIUM: chiamo decoder con ", jm);

                    show_CustomUIKit_PageLoader();
                    areaPremiumService.areaPremium(jm, function(response) {

                        if (response && response.data) {

                            var newResp = JSON.parse(response.data);

                            //check strumento in listacarte       

                            var mioLibretto = vm.home_Confs.rapporti.filter(
                                function(itm) {
                                    return itm.numeroRapporto == newResp.strumento;
                                });

                            var isMioLibretto = (vm.home_Confs.rapporti && vm.home_Confs.rapporti.length > 0 && mioLibretto.length > 0) ? true : false;

                            if (isMioLibretto) {

                                manageClickOnBoxLib(mioLibretto[0]);
                            } else {
                                hide_CustomUIKit_PageLoader();
                            }

                        } else {
                            hide_CustomUIKit_PageLoader();
                        }

                    }, function(error) {
                        hide_CustomUIKit_PageLoader();
                    });
                }


		function richiediRapporti(callBackEmpty) {

			UtenteService.rapporti(null,
				function (response) {
					if (response.data !== undefined && response.data !== null && response.data !== "") {
						vm.home_Confs.rapporti = response.data.listaRapporti;

						if (vm.home_Confs.rapporti) {

							vm.home_Confs.rapportiPresenzaBfp = [];
							for (var i = 0; i < vm.home_Confs.rapporti.length; i++) {
								// Iterate over rapporti with presenzaBfp
								if (vm.home_Confs.rapporti[i].presenzaBfp === "S")
									vm.home_Confs.rapportiPresenzaBfp.push(vm.home_Confs.rapporti[i]);

								vm.home_Confs.rapporti[i].onBoardingFlag =
									(
										vm.home_Confs.rapporti[i].statoInquiryRPOL != 'A' &&
										vm.home_Confs.rapporti[i].tipoLibretto == 'N' &&
										(vm.home_Confs.rapporti[i].formaLibretto == 'C' || vm.home_Confs.rapporti[i].formaLibretto == 'D')
									) ? true : false;
							}
						}

						vm.home_Confs.librettoSmartDispositivo = response.data.librettoSmartDispositivo;
						vm.home_Confs.librettoSmartInformativoSenzaTel = response.data.librettoSmartInformativoSenzaTel;
						vm.home_Confs.librettoSmartInformativoSenzaTelCC = response.data.librettoSmartInformativoSenzaTelCC;
						vm.home_Confs.librettoSmartInformativoConTel = response.data.librettoSmartInformativoConTel;
						vm.home_Confs.librettoSmartDispositivoIban = response.data.librettoSmartDispositivoIban;
						vm.home_Confs.listaBuoniSuConto = response.data.listaBuoniSuConto;
						$log.log('UtenteService.rapporti:', response);
						vm.listaLibrettiInvocazione.confs = vm.home_Confs;

						sessionStorage.setItem("librettoSmartInformativoSenzaTel", vm.home_Confs.librettoSmartInformativoSenzaTel);

						if (vm.home_Confs.rapporti.length > 0)
							vm.listaLibrettiInvocazione.invocato = "F";
						else
							vm.listaLibrettiInvocazione.invocato = "T";
						/*vm.listaLibrettiInvocazione.isGold = vm.isGold;*/

						//Webtrends invio lista prodotti cliente 
						WebtrendsService.WT_atterraggioHomePage(vm.home_Confs.librettoSmartDispositivo, vm.home_Confs.librettoSmartDispositivoIban,
							vm.home_Confs.librettoSmartInformativoSenzaTelCC, vm.home_Confs.librettoSmartInformativoConTel,
							vm.home_Confs.librettoSmartInformativoSenzaTel, false);

						// satellite
						var more = {
							'rpol_seg_flg_SmartDispo': '' + vm.home_Confs.librettoSmartDispositivo,
							'rpol_seg_flg_noprodotti': 'false',
							'rpol_seg_flg_SmartDispoIban': '' + vm.home_Confs.librettoSmartDispositivoIban,
							'rpol_seg_flg_SmartInfoNoTelSiCC': '' + vm.home_Confs.librettoSmartInformativoSenzaTelCC,
							'rpol_seg_flg_SmartInfoSiTel': '' + vm.home_Confs.librettoSmartInformativoConTel,
							'rpol_seg_flg_SmartInfoNoTel': '' + vm.home_Confs.librettoSmartInformativoSenzaTel
						};
						SatelliteService.trackPageView("id_segmento_rpol", more);



						// AREA PREMIUM
						checkPremium();
						// AREA PREMIUM


						//INIZIO GESTIONE CAROUSEL
						initCarousel();
						//FINE GESTIONE CAROUSEL

						verificaConfigurazioniAbilitate();
					}
					vm.showErrorRapporti = false;
					vm.showEsito = false;
					richiediPratiche();
					fatcaControlla();
					praticheSecurizzazioneLibretto();
				},
				function (httpResponseError) {
					// SE HTTP == 453 PRIMO WARNING MOSTRO IL MESSAGGIO DI WARNING CHE MI RESTITUISCI ELSE PAGINA DI ERRORE	CON ANNULLA E RIPROVA
					vm.messaggioErroreWarning = vm.getErrorResponseMessage(httpResponseError);
					if (httpResponseError.status === 453) {
						webtrendsLogError(null, vm.messaggioErroreWarning);
						vm.showEsitoWarning = true;
						vm.showErrorRapporti = false;
					} else if (httpResponseError.status === 452) {
						//NO PRODOTTI ASSOCIATI
						vm.showEsitoNoProdottiPage = true;
						vm.listaLibrettiInvocazione.invocato = "NP";
						//Webtrends invio lista prodotti cliente - noProdotti
						WebtrendsService.WT_atterraggioHomePage(false, false, false, false, false, true);

						// satellite
						var more = {
							'rpol_seg_flg_SmartDispo': 'false',
							'rpol_seg_flg_noprodotti': 'true',
							'rpol_seg_flg_SmartDispoIban': 'false',
							'rpol_seg_flg_SmartInfoNoTelSiCC': 'false',
							'rpol_seg_flg_SmartInfoSiTel': 'false',
							'rpol_seg_flg_SmartInfoNoTel': 'false'
						};
						SatelliteService.trackPageView("id_segmento_rpol", more);
						vm.showErrorRapporti = false;
						initCarousel();
						verificaConfigurazioniAbilitate();
						fatcaControlla();
					} else {
						webtrendsLogError(null, vm.messaggioErroreWarning);
						configureEsitoPage(vm.messaggioErroreWarning);
						vm.showEsito = true;
						vm.showErrorRapporti = true;
					}

					richiediPratiche();
					/* $timeout(function () {
						hide_CustomUIKit_PageLoader()
					}, 0); */
				}
			);
		}

		function richiediPratiche(callBackEmpty) {
			UtenteService.listaPratiche(null,
				function (response) {
					if (response.data !== undefined && response.data !== null && response.data !== "") {
						// controllo pratica per cancellazione
						var listaPratiche = response.data.listaPratiche;
						for (var i = 0; i < vm.rangeStati.length; i++) {
							for (var j = 0; j < listaPratiche.length; j++) {
								if (listaPratiche[j].codStato == vm.rangeStati[i]) {
									vm.numeroPratica = listaPratiche[j].numeroPratica;
									vm.gestionePratica = true;
									listaPratiche.splice(listaPratiche[j], 1);
								}
							}
						}
						vm.home_Confs.pratiche = listaPratiche;
						// verificaConfigurazioniAbilitate();
					}
					vm.showErrorePratiche = false;
					richiediRapportiMinori();
					vm.showEsito = false;
					praticheSecurizzazioneLibretto();
				},
				function (httpResponseError) {

					//Exception Handler not Used

					/* 	$timeout(function () {
							hide_CustomUIKit_PageLoader()
						}, 0); */
					vm.showErrorePratiche = true;
					richiediRapportiMinori();
				}
			);
		}

		function praticheSecurizzazioneLibretto(callBackEmpty) {
			UtenteService.praticheSecurizzazioneLibretto(null,
				function (response) {
					if (response.data !== undefined && response.data !== null && response.data !== "") {

						vm.home_Confs.SecurizzazioneLibretto.titolo = response.data.titolo;
						vm.home_Confs.SecurizzazioneLibretto.numeroLibrettoSecurizzato = response.data.numeroLibretto;
						vm.home_Confs.SecurizzazioneLibretto.descrizione = response.data.descrizione;
						vm.home_Confs.SecurizzazioneLibretto.ctaName = response.data.ctaName;
						vm.home_Confs.SecurizzazioneLibretto.ctaUrl = response.data.ctaUrl;

					}
				},
				function (httpResponseError) {
					vm.showErrorePratiche = true;
				}
			);
		}


		function checkSecurizzazioneLibretto(param) {
			if (vm.home_Confs.SecurizzazioneLibretto.descrizione != null) {
				// mostro warning nella modale
				vm.home_Confs.showModalWarning = true;
			} else {
				sessionStorage.setItem("E2EnumeroLibretto", param);
				window.location.href = "/rpol/private/pages/abilitazioneE2Elibretto.html";
			}
		}

		function initCarousel() {
			//GESTIONE STEP DEL CAROUSEL
			vm.home_Confs.conteggioSliderA = 0;
			vm.home_Confs.conteggioSliderB = 0;
			vm.home_Confs.conteggioSliderC = 0;
			vm.home_Confs.conteggioSliderD = 0;
			vm.home_Confs.conteggioSliderE = 0;

			if (vm.home_Confs.librettoSmartDispositivo == true && vm.home_Confs.librettoSmartDispositivoIban === false) {
				vm.home_Confs.conteggioSliderB = 0;
			} else if (vm.home_Confs.librettoSmartDispositivo == true && vm.home_Confs.librettoSmartDispositivoIban === true) {
				vm.home_Confs.conteggioSliderB = 1;
			}

			if (vm.home_Confs.librettoSmartInformativoSenzaTelCC == true && vm.home_Confs.librettoSmartDispositivoIban === false &&
				vm.home_Confs.librettoSmartDispositivo == false) {
				vm.home_Confs.conteggioSliderC = 0;
			} else if (vm.home_Confs.librettoSmartInformativoSenzaTelCC == true && vm.home_Confs.librettoSmartDispositivoIban === true &&
				vm.home_Confs.librettoSmartDispositivo == false || vm.home_Confs.librettoSmartInformativoSenzaTelCC == true && vm.home_Confs.librettoSmartDispositivoIban === false &&
				vm.home_Confs.librettoSmartDispositivo == true || vm.home_Confs.librettoSmartInformativoSenzaTelCC == true && vm.home_Confs.librettoSmartDispositivoIban === true &&
				vm.home_Confs.librettoSmartDispositivo == true) {
				vm.home_Confs.conteggioSliderC = vm.home_Confs.conteggioSliderB + 1;
			}

			if (vm.home_Confs.librettoSmartInformativoConTel == true && vm.home_Confs.librettoSmartDispositivoIban === false &&
				vm.home_Confs.librettoSmartDispositivo == false && vm.home_Confs.librettoSmartInformativoSenzaTelCC == false) {
				vm.home_Confs.conteggioSliderD = 0;
			} else {
				if (vm.home_Confs.librettoSmartInformativoSenzaTelCC == true)
					vm.home_Confs.conteggioSliderD = vm.home_Confs.conteggioSliderC + 1;
				else
					vm.home_Confs.conteggioSliderD = vm.home_Confs.conteggioSliderB + 1;
			}
			//banner librettoSmartInformativoIban
			if (vm.home_Confs.librettoSmartInformativoSenzaTel === true && vm.home_Confs.librettoSmartInformativoConTel == false && vm.home_Confs.librettoSmartDispositivoIban === false &&
				vm.home_Confs.librettoSmartInformativoSenzaTelCC == false && vm.home_Confs.librettoSmartInformativoConTel == false) {
				vm.home_Confs.conteggioSliderE = 0;
			} else {
				if (vm.home_Confs.librettoSmartInformativoConTel == true)
					vm.home_Confs.conteggioSliderE = vm.home_Confs.conteggioSliderD + 1;
				else if (vm.home_Confs.librettoSmartInformativoSenzaTelCC == true)
					vm.home_Confs.conteggioSliderE = vm.home_Confs.conteggioSliderC + 1;
				else
					vm.home_Confs.conteggioSliderE = vm.home_Confs.conteggioSliderB + 1;

			}

			$timeout(function () {
				if (vm.showEsitoNoProdottiPage == true) {

					document.getElementById("noProdotti").className += " active";
					return;
				}
				if (vm.home_Confs.librettoSmartDispositivo == true) {
					/*document.getElementById("librettoSmartDispositivo").classList.remove("active");
					document.getElementById("librettoSmartInformativoConTel").classList.remove("active");*/

					document.getElementById("librettoSmartDispositivo").className += " active";
					return;
				}
				if (vm.home_Confs.librettoSmartDispositivoIban == true) {
					/*document.getElementById("librettoSmartInformativoConTel").classList.remove("active");
					document.getElementById("librettoSmartInformativoSenzaTel").classList.remove("active");*/

					document.getElementById("librettoSmartDispositivoIban").className += " active";
					return;
				}
				if (vm.home_Confs.librettoSmartInformativoSenzaTelCC == true) {
					/*document.getElementById("librettoSmartDispositivo").classList.remove("active");
					document.getElementById("librettoSmartInformativoConTel").classList.remove("active");*/

					document.getElementById("librettoSmartInformativoSenzaTelCC").className += " active";
					return;
				}
				if (vm.home_Confs.librettoSmartInformativoConTel == true) {

					/*document.getElementById("librettoSmartDispositivo").classList.remove("active");
					document.getElementById("librettoSmartInformativoSenzaTel").classList.remove("active");*/

					document.getElementById("librettoSmartInformativoConTel").className += " active";
					return;
				}
				if (vm.home_Confs.librettoSmartInformativoSenzaTel == true) {
					/*document.getElementById("librettoSmartDispositivo").classList.remove("active");
					document.getElementById("librettoSmartInformativoConTel").classList.remove("active");*/

					document.getElementById("librettoSmartInformativoSenzaTel").className += " active";
					return;
				}

			}, 200);
			//FINE GESTIONE STEP DEL CAROUSEL

		}

		function verificaConfigurazioniAbilitate() {
			ConfigurazioniService.configurazioni({
				data: {
					codiceServizio: AppConfs[vm.confs.mainFunction].codiceServizioGestioneBuoni,
					serviceOpen: true
				}
			},
				function (response) {
					// Funzione per controllo dei semafori
					var listaConfigurazioni = response.data.listaConfigurazioni;
					var semaforoBFP = {};
					var semaforoDR = {};
					if (listaConfigurazioni) {
						for (el in listaConfigurazioni) {
							if (listaConfigurazioni[el].chiave == 'SEMAFORO_BFP') {
								semaforoBFP = listaConfigurazioni[el];
							}
							if (listaConfigurazioni[el].chiave == 'SEMAFORO_DR') {
								semaforoDR = listaConfigurazioni[el];
							}
						}
						if (semaforoBFP.valore == 1 && semaforoDR.valore == 1) {
							vm.disabilitaServizi = false;
						} else {
							vm.disabilitaServizi = true;
						}
					} else {
						vm.disabilitaServizi = false;
					}

					$log.log('ConfigurazioniService.codiceServizioGestioneBuoni:', response);
					vm.showEsito = false;

					if (response.metadata !== undefined && response.metadata !== null && response.metadata !== "") {
						vm.showEsitoWarning = true;
						vm.messaggioErroreWarning = response.metadata.message;
					}

					$timeout(function () {
						hide_CustomUIKit_PageLoader();
						// angular.element('.modal-backdrop').remove();
						// Funzioni per disabilitare servizi
						if (vm.disabilitaServizi) {
							var buttons = document.getElementsByClassName('ctaDisabled');
							for (var i = 0; i < buttons.length; i++) {
								buttons[i].style.backgroundColor = "#f6f6f6";
								buttons[i].style.color = "#d0d0d0";
								buttons[i].style.borderColor = "#f6f6f6";
								buttons[i].style.pointerEvents = "none";
							}
							var links = document.getElementsByClassName('linkDisabled');
							for (var i = 0; i < links.length; i++) {
								links[i].style.opacity = "0.5";
								links[i].style.pointerEvents = "none";
							}
						}
						handleModali();
					}, 2000);
				},
				function (httpResponseError) {
					$log.log('ConfigurazioniService.codiceServizioGestioneBuoni:', httpResponseError);
					// SE HTTP == 453 PRIMO WARNING MOSTRO IL MESSAGGIO DI WARNING CHE MI RESTITUISCI ELSE PAGINA DI ERRORE	CON ANNULLA E RIPROVA
					vm.messaggioErroreWarning = vm.getErrorResponseMessage(httpResponseError);
					if (httpResponseError.status === 453) {
						vm.showEsitoWarning = true;
						vm.showEsitoBuoni = true;
						vm.home_Confs.librettoSmartDispositivo = false;
						//INIZIO GESTIONE CAROUSEL
						initCarousel();
						//FINE GESTIONE CAROUSEL
					} else {
						webtrendsLogError(null, vm.messaggioErroreWarning);
						configureEsitoPage(vm.messaggioErroreWarning);
						vm.showEsito = true;
					}
					$timeout(function () {
						hide_CustomUIKit_PageLoader()
					}, 0);
				}
			);
		}

		function fatcaControlla(){
			FatcaService.controlla(null,
				function (response) {
					if (response.data && response.data.complianceResult == "KO") {
						if(response.data.codiceErrore){
							vm.fatcaError = response.data.codiceErrore;
						}
					}
					$log.log('ManagerController FatcaService.controlla response:', response);
				},
				function (httpResponseError) {
					vm.messaggioErroreWarning = vm.getErrorResponseMessage(httpResponseError);
					webtrendsLogError(null, vm.messaggioErroreWarning);
					configureEsitoPage(vm.messaggioErroreWarning);
					vm.showEsito = true;
					forceHide_CustomUIKit_PageLoader();
			});
		}

		function handleModali() {
			//check titolare effettivo = true, non devo visualizzarlo
			if (vm.home_Confs.titolareEffettivo) {
				//Gestione visualizzazione modali
				// le modali devono essere visualizzate solo se all'interno della stessa sessione utente
				//non sono mai state visualizzate
				var showModal = $cookies.get('showModaliCertificaAssocia');
				if (!showModal || showModal === 'true') {
					$('#myModal').modal({
						backdrop: 'static',
						keyboard: false
					}).modal('show');
				}else{
					// modale fatca si vede anche dopo prima visione showModaliCertificaAssocia
					showFatcaModale();
				}
				if(!vm.home_Confs.librettoSmartInformativoConTel && !vm.home_Confs.librettoSmartInformativoSenzaTelCC) {
					// quando non ci ono altri modali caso specifico
					showFatcaModale();
				} if (vm.home_Confs.librettoSmartInformativoSenzaTel == true && !vm.home_Confs.librettoSmartInformativoSenzaTelCC) {
					for (var i = 0; i < vm.home_Confs.rapporti.length; i++) {
						if (vm.home_Confs.rapporti[i].tipoLibretto == 'S' && vm.home_Confs.rapporti[i].numeroRapporto != vm.home_Confs.SecurizzazioneLibretto.numeroLibrettoSecurizzato && (vm.home_Confs.rapporti[i].prefissoCellulare == '' || vm.home_Confs.rapporti[i].prefissoCellulare == undefined) && (vm.home_Confs.rapporti[i].numeroCellulare == '' || vm.home_Confs.rapporti[i].numeroCellulare == undefined)) {

							vm.home_Confs.Nrapporto = vm.home_Confs.rapporti[i].numeroRapporto;
							i++;
							vm.index = i;


							$('#phoneNumberModal').modal({
								backdrop: 'static',
								keyboard: false
							}).modal('show');
							break
						}
					}
				}
			} else {
				$('#loginModal').modal({
					backdrop: 'static',
					keyboard: false
				}).modal('show');
				$('#loginModal .modal-content .alert-check').hide();
			}
		}

		//setto il cookie per non visualizzare le modali
		//e atterro sulla dispositiva

		function eseguiDispositiva(path) {
			$cookies.put('showModaliCertificaAssocia', 'false');
			window.location = path;
		}

		vm.settaActive = function (input) {

			if (input === "librettoSmartDispositivoIban") {
				/*document.getElementById("librettoSmartInformativoConTel").classList.remove("active");
				document.getElementById("librettoSmartInformativoSenzaTel").classList.remove("active");*/

				document.getElementById("librettoSmartDispositivoIban").className += " active";
			}
			if (input === "librettoSmartInformativoSenzaTel") {
				/*document.getElementById("librettoSmartDispositivo").classList.remove("active");
				document.getElementById("librettoSmartInformativoConTel").classList.remove("active");*/

				document.getElementById("librettoSmartInformativoSenzaTel").className += " active";
			}
			if (input === "librettoSmartInformativoConTel") {
				/*document.getElementById("librettoSmartDispositivo").classList.remove("active");
				document.getElementById("librettoSmartInformativoSenzaTel").classList.remove("active");*/

				document.getElementById("librettoSmartInformativoConTel").className += " active";
			}
			if (input === "librettoSmartDispositivo") {
				/*document.getElementById("librettoSmartInformativoConTel").classList.remove("active");
				document.getElementById("librettoSmartInformativoSenzaTel").classList.remove("active");*/

				document.getElementById("librettoSmartDispositivo").className += " active";
			}


		};

		function rowButtons_ButtonClick_Handler(clickAction) {
			$log.log('APPController rowButtons_ButtonClick_Handler clickAction :' + clickAction);

			vm[clickAction]();
		}


		function homeLoad_Handler() {
			$log.log('APPController homeLoad_Handler ');

			var redirect = window.location.pathname.split("index.html", 1);
			window.location = redirect + 'index.html';
		}

		function configureEsitoPage(errorResponseMessage) {

			$log.log('APPController configureEsitoPage errorResponseMessage: ' + errorResponseMessage);
			$log.log('APPController configureEsitoPage AppConfs[vm.confs.mainFunction].esitoPage.exception0: ' + AppConfs[vm.confs.mainFunction].esitoPage.exception0);
			vm.home_Confs.esitoPage = AppConfs[vm.confs.mainFunction].esitoPage.exception0;
			vm.rowButtons_Confs = vm.home_Confs.esitoPage.rowButtons;

			vm.home_Confs.esitoPage.bodyMsg =
				errorResponseMessage ?
					errorResponseMessage : [vm.home_Confs.esitoPage.bodyMsg];
		}

		function webtrendsLogError(uri_errore, errorResponseMessage) {

			WebtrendsService.WT_mess_errore(uri_errore, errorResponseMessage);

			SatelliteService.track_error(errorResponseMessage);

		}

		function getErrorResponseMessage(errorResponse) {

			$log.log("ManagerController getErrorResponseMessage START");

			var errorResponseMessage;


			if (errorResponse.data && errorResponse.data.metadata) {

				if (errorResponse.data.metadata.message)
					errorResponseMessage = [errorResponse.data.metadata.message];
				else if (errorResponse.data.metadata.errors)
					errorResponseMessage = errorResponse.data.metadata.errors;

			}

			return errorResponseMessage;

		}

		function checkTipologiaConto(key, numeroRapporto, tipologia, path) {
			UtenteService.tipologiaConto({
				data: {
					numeroRapporto: numeroRapporto
				}
			},
				function (response) {
					$log.log('ManagerController  UtenteService.tipologiaConto response: ', response);

					if (response.data) {
						path = response.data.link;
					}

					$log.log('ManagerController  UtenteService.tipologiaConto path: ', path);
					//modifica per non portare lo sviluppo in collaudo
					/* 	path = 'https://bancopostaonline.poste.it/BPOL/bancoposta/Servizi/BuoniFruttiferi/BuoniFruttiferi.aspx'; */
					storeDatakey(key, numeroRapporto, tipologia, path);
				},
				function (httpResponse) {
					$log.log('ManagerController UtenteService.tipologiaConto error-response: ', httpResponse);
					//modifica per non portare lo sviluppo in collaudo
					/* 	path = 'https://bancopostaonline.poste.it/BPOL/bancoposta/Servizi/BuoniFruttiferi/BuoniFruttiferi.aspx'; */

					$log.log('ManagerController  UtenteService.tipologiaConto path: ', path);
					storeDatakey(key, numeroRapporto, tipologia, path);
				});
		}

		vm.newPage = function (tipologia, numeroRapporto, path) {
			var key = "";
			var path = '/rpol/private/pages/index.html';

			switch (tipologia) {
				case 'libretto':
					key = "numeroRapporto_libretti";
					storeDatakey(key, numeroRapporto, tipologia, path);
					break;
				case 'buonoLibretto':
					key = "numeroRapporto_posizioneBuoni";
					storeDatakey(key, numeroRapporto, tipologia, path);
					break;
				case 'buonoConto':
					checkTipologiaConto(key, numeroRapporto, tipologia, path);
					break;
				case 'patrimonioBuoni':
					key = "numeroRapporto_patrimonioBuoni";
					storeDatakey(key, numeroRapporto, tipologia, path);
					break;
				case 'pratica':
					key = "numeroPratica_listaPratiche";
					storeDatakey(key, numeroRapporto, tipologia, path);
					break;
				default:
					$log.log('Error no tipo founded.')
					break;
			}
			/*cryptNumeroRapporto(numeroRapporto);*/
		};


		vm.newPage_Handler = function (key, path) {

			var request = JSON.parse('{ "data": { "mappaVariabiliTemp": { "' + key + '": "" } } }');
			$log.log('ManagerController json :', request);

			UtenteService.inserisci(request,
				function (response) {
					$log.log('ManagerController  UtenteService.inserisci response: ', response);
					window.location = path;
				},
				function (httpResponse) {
					$log.log('ManagerController UtenteService.inserisci error-response: ', httpResponse);
					window.location = path;
				});
		};


		function storeDatakey(key, numeroRapporto, tipologia, path) {

			var request = JSON.parse('{ "data": { "mappaVariabiliTemp": { "' + key + '": "' + numeroRapporto + '", "tipologia": "' + tipologia + '"} } }');
			$log.log('ManagerController json :', request);


			//Non vado in errore perchè la gestione dell'errore si fa sul recupera nelle landing
			UtenteService.inserisci(request,
				function (response) {
					$log.log('ManagerController  UtenteService.inserisci response: ', response);
					redirectPage(tipologia, path);
				},
				function (httpResponse) {
					$log.log('ManagerController UtenteService.inserisci error-response: ', httpResponse);
					redirectPage(tipologia, path);
				});

		}

		function redirectPage(tipologia, path) {

			switch (tipologia) {
				case 'home':
					/*cryptNumeroRapportoSlider(numeroRapporto, tipologia);
					sessionStorage.removeItem('numeroRapporto');*/
					window.location = '/rpol/private/pages/index.html';
					return;
				case 'libretto':
					/*cryptNumeroRapportoSlider(numeroRapporto, tipologia);*/
					window.location = '/rpol/private/pages/libretto.html';
					return;
				case 'buonoLibretto':
					/*cryptNumeroRapportoSlider(numeroRapporto, tipologia);*/
					window.location = '/rpol/private/pages/posizione-buoni.html';
					return;
				case 'buonoConto':
					/*	window.location = 'https://bancopostaonline.poste.it/BPOL/bancoposta/Servizi/BuoniFruttiferi/BuoniFruttiferi.aspx' ;*/
					window.location = path;
					return;
				case 'patrimonioBuoni':
					window.location = '/rpol/private/pages/patrimonio-buoni.html';
					return;
				case 'pratica':
					window.location = '/rpol/private/pages/cruscotto-pratiche.html';
					return;
				case 'minori':
					window.location = '/rpol/private/pages/libretto-minori.html';
					return;
				default:
					$log.log("No redirect founded");
					break;
			}
		}


		function toOnBoardingLibApp(numeroRapporto, formaLibretto) {

			var path = "/rpol/private/pages/on-boarding-lib.html";
			var request = JSON.parse('{ "data": { "mappaVariabiliTemp": { "numeroRapporto_onBoarding": "' + numeroRapporto + '","formaLibretto_onBoarding": "' + formaLibretto + '" } } }');
			$log.log('ManagerController toOnBoardingLibApp json :', request);


			UtenteService.inserisci(request,
				function (response) {
					$log.log('ManagerController  UtenteService.inserisci response: ', response);
					window.location = path;
				},
				function (httpResponse) {
					//GESTIONE DELL'ERRORE DELEGATA AL RECUPERA SU ON-BOARDING-LIB
					$log.log('ManagerController UtenteService.inserisci error-response: ', httpResponse);
					window.location = path;
				}
			);

		}

		function manageClickOnBoxLib(rapporto) {

			$log.log('ManagerController manageClickOnBoxLib rapporto', rapporto);

			if (rapporto.onBoardingFlag == true)
				toOnBoardingLibApp(rapporto.numeroRapporto, rapporto.formaLibretto);
			else
				vm.newPage('libretto', rapporto.numeroRapporto);

		}

		function manageClickOnBoxPratica(pratica) {
			$log.log('ManagerController manageClickOnBoxPratica pratica', pratica);
			vm.newPage('pratica', pratica.numeroPratica);
		}

		function manageClickOnLibrettoMinori() {
			redirectPage('minori', null);
		}


		function closeModale(idModale) {
			//chiusura modale carousel
			if (idModale === 'myModal') {
				$cookies.put('showModaliCertificaAssocia', 'false');
				//UikitProxy.applyCustomFile();
				//show_WaitingAction_PageLoader();
				$('#myModal').modal('hide');
				showFatcaModale();
			} else if(idModale === 'fatcaModal') {
				$('#fatcaModal').modal('hide');
			} else if (idModale === 'phoneNumberModal') {
				$('#phoneNumberModal').modal('hide');
				if (vm.home_Confs.librettoSmartInformativoSenzaTel == true && !vm.home_Confs.librettoSmartInformativoSenzaTelCC) {
					for (var i = vm.index; i < vm.home_Confs.rapporti.length; i++) {
						if (vm.home_Confs.rapporti[i].tipoLibretto == 'S' && vm.home_Confs.rapporti[i].numeroRapporto != vm.home_Confs.SecurizzazioneLibretto.numeroLibrettoSecurizzato && (vm.home_Confs.rapporti[i].prefissoCellulare == '' || vm.home_Confs.rapporti[i].prefissoCellulare == undefined) && (vm.home_Confs.rapporti[i].numeroCellulare == '' || vm.home_Confs.rapporti[i].numeroCellulare == undefined)) {
							vm.home_Confs.Nrapporto = vm.home_Confs.rapporti[i].numeroRapporto;
							i++;
							vm.index = i;
							vm.home_Confs.showModalWarning = false;
							setTimeout(function () {
								$('#phoneNumberModal').modal({
									backdrop: 'static',
									keyboard: false
								}).modal('show');
							}, 500)
							break
						}
					}
				}
			} else {
				//chiusura modale titolare effettivo
				if ($('#loginModal .custom-checkbox').hasClass('selected')) {
					$('#loginModal').modal('hide');
					$('#myModal').modal('show');

					//AGGIORNO TITOLAREEFFETTIVO
					UtenteService.titolareEffettivo({
						data: {
							tipoOperazione: 'conferma'
						}
					},
						function (response) {
							$log.log('UtenteService.titolareEffettivo:', response);
						},
						function (httpResponse) {
							$log.log('UtenteService.titolareEffettivo:', httpResponse);
						}
					);
				} else {
					$('#loginModal .modal-content .alert-check').show();
				}
			}
			//hide_WaitingAction_PageLoader();
		}

		function showFatcaModale(){
			if(vm.fatcaError && vm.fatcaError !== ''){
				$('#fatcaModal').modal({
					backdrop: 'static',
					keyboard: false
				}).modal('show');
			}
		}

		/**
	 * on errorScaEvent
	 */
	$scope.$on('errorScaEvent', function(event, params) {
		show_CustomUIKit_PageLoader();
		var message = vm.getErrorResponseMessage(params.error.httpResponse);	
		configureEsitoPage(message);
		vm.showEsito = true;
		forceHide_CustomUIKit_PageLoader(true);
	});

	/**
	 * on errorAuthEvent
	 */
	$scope.$on('errorAuthEvent', function(event, params){
		show_CustomUIKit_PageLoader();
		var errorResponseMessage = null;
		if(params)
		errorResponseMessage = vm.getErrorResponseMessage(params.error.httpResponse);	
		configureEsitoPage(errorResponseMessage);
		vm.showEsito = true;
		forceHide_CustomUIKit_PageLoader(true);
	  
	})

	}
]);
